import React from "react"
import { useNavigate  } from 'react-router-dom';

import {Typography, Grid, Button, Container} from "@mui/material"
import "./styles.css"

const Setup = ({name}) => {
    const navigate = useNavigate ()

    const handleClick =  () => {
        navigate('/setup/')
      };



    return (
        <>
            <Typography align="center" variant="h3" >
                Hi {name}!
            </Typography>
            <Typography align="center" variant="h3" >
                It seems that you haven't set up your account.
            </Typography>
            <div className="click">
                <Button variant="contained" color="primary" onClick={handleClick}>
                    Click here!
                </Button>

            </div>
                
        </>
    )

}
export default Setup