import React from "react";
import './styles.css'
import Image from '../../images/cmsbgnew.jpg'
import Logo from '../../images/CMSlogo.jpg'
import { Button } from "@mui/material";
import { useNavigate  } from 'react-router-dom';


const Home = ({isAdmin}) => {
    const navigate = useNavigate ()
    
    const styles = {
        paperContainer: {
            width: '100%',
            height: '100vh',
            backgroundImage: `url(${Image})`,
            color: '#000',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
        }
    };

    return(
        <div style={styles.paperContainer}>
            {/* <div className="logo">
                <img className="homeLogo" src={Logo} alt="CMS Consulting Inc. logo"/>
            </div> */}
              <div class="mx-4 md:mx-40 p-2">
                <h1 class="text-center text-7xl mt-20 md:text-5xl lg:text-9xl font-bold font-sans text-slate-100 shadow-xl border rounded-full">
                    QUOTE SYSTEM
                </h1>
            </div>
            <div className="w-1/2 mx-auto gap-8 flex flex-col pt-24">  
                <Button sx={{ p: 2,m:1}} 
                    variant="contained" 
                    color="primary" 
                    size="small" 
                    type="save"
                    onClick={() => {navigate('/createQuote/')}}
                    ><div className="p-2 font-bold text-3xl text-slate-100 font-sans">Create Quote</div>
                </Button>
                {isAdmin &&(
                <Button sx={{ p: 2,m:1}} 
                    variant="contained" 
                    color="primary" 
                    size="small" 
                    type="save"
                    onClick={() => {navigate('/createProposal/')}}
                    ><div className="p-2 font-bold text-3xl text-slate-100 font-sans">Create Proposal</div>
                </Button>
                )}
            </div>
        </div>
    )
}

export default Home