import React, { useState, useEffect } from 'react'
import {BrowserRouter, Route, Routes } from 'react-router-dom'
import Navigation from './components/Navigation/Navigation'
import Home from './components/Pages/Home'
import Profile from './components/Profile/Profile'
import CreateQuote from './components/Form/CreateQuote'
import CreateProposal from './components/Form/CreateProposal'
import Items from './components/Pages/SaleItems'
import ViewSalesItem from './components/Sale/SaleTable'
import ViewQuotes from './components/Quote/QuoteTable'
import ViewProposal from './components/Proposal/ProposalTable'
import ViewQuoteAdmin from './components/PDFs/PDFViewAdmin'
import ViewProposalAdmin from './components/Proposal/PDF/PDFAdmin'
import PDF from './components/PDFs/PDF'
import Auth from './components/Auth/Auth';
import Setup from './components/Auth/Setup';
import {GoogleOAuthProvider} from '@react-oauth/google';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

function App () {
  const [isAdmin, setIsAdmin] = useState(false);

const mail = JSON.parse(localStorage.getItem('profile'));

useEffect(() => {
  const access = mail?.result.access;
  const email = mail?.result.email;
  
  // Check if the email contains either of the specified domains
  const isAdminEmail = email && (email.includes('cmsconsulting.email') || email.includes('cmsconsulting.ca'));
  
  if (isAdminEmail || access === process.env.REACT_APP_ADMIN) {
    setIsAdmin(true);
  } else {
    setIsAdmin(false);
  }
}, [mail]);
  
  return (
    <GoogleOAuthProvider clientId = {process.env.REACT_APP_GOOGLE_AUTH_API}>
    
    <BrowserRouter>

      <Grid container >
        <Grid item xs={12} sm={2}>
          
            <Navigation isAdmin={isAdmin}/>
        </Grid>
        {isAdmin? (

          <Grid item xs={12} md={10}>
          <Grid item xs={12} md={12}>
            <Routes>
              <Route path='auth' element={<Auth/>}/>
              <Route path='setup' element={<Setup/>}/>
              <Route path='/' element={<Home isAdmin={isAdmin}/>}/>
              <Route path='createQuote' element={<CreateQuote/>}/>
              <Route path='createProposal' element={<CreateProposal/>}/>
              <Route path='addItem' element={<Items/>}/>
              <Route path='viewItems' element={<ViewSalesItem/>}/>
              <Route path='viewQuote' element={<ViewQuotes />}/>
              <Route path='viewProposal' element={<ViewProposal />}/>
              <Route path='pdf' element={<PDF/>}/>
              <Route path='profile' element={<Profile/>}/>
              <Route path='viewQuoteAdmin' element={<ViewQuoteAdmin  />}/>
              <Route path='viewProposalAdmin' element={<ViewProposalAdmin  />}/>
              
            </Routes>
          </Grid>
          </Grid>


        ):(

        <Grid item xs={12} md={10}>
            <Grid item xs={12} md={12}>
              <Routes>
                <Route path='auth' element={<Auth/>}/>
                <Route path='/' element={<Home/>}/>
                <Route path='createQuote' element={<CreateQuote/>}/>
                <Route path='viewQuote' element={<ViewQuotes />}/>
                <Route path='viewQuoteAdmin' element={<ViewQuoteAdmin  />}/>
                <Route path='pdf' element={<PDF/>}/>
                <Route path='profile' element={<Profile/>}/>
                <Route path='setup' element={<Setup/>}/>
                
              </Routes>
            </Grid>
        </Grid>
        )}
      </Grid>

      
    </BrowserRouter> 
    </GoogleOAuthProvider>  
  )
}

export default App
