import React, {useEffect, useState, useRef} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getItems, deleteItem} from '../../actions/items';
import Items from '../Form/Item'
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  Dialog  , DialogActions ,DialogContent, DialogTitle, Button   } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';

import './styles.css'

const SaleTable = () => {     
  const dispatch = useDispatch();
  const [currentId, setCurrentId] = useState(0);
  const items = useSelector((state) => state.items);
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState('paper');

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(getItems());
  }, [dispatch]);

  //Higlight Selected Sale Item
  const [selectedRow, setSelectedRow] = useState(null);
  const handleRowClick = (index) => {
    setSelectedRow(index);
  };

  return (
    <Grid item sx={{m:1, width:'98%'}}>
      <h1 className='text-xl uppercase font-semibold text-slate-500'>Sale Items Lists</h1>
      <TableContainer component={Paper} className="table">
      <Table sx={{ minWidth: 400 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell><Button size="small" color="primary" onClick={() => {}} component={Link} to="/addItem" >
                    <AddIcon fontSize="large"/>
                  </Button></TableCell>
            <TableCell align="left">Sale Items</TableCell>
            <TableCell align="left">Sale Item Type</TableCell>
            <TableCell align="left">Price</TableCell>
            <TableCell align="left">Price Type</TableCell>
            <TableCell align="left">Cost</TableCell>
            <TableCell align="left">Cost Type</TableCell>
            <TableCell align="left">Estimated Duration</TableCell>
            <TableCell align="left">Qualifying Revenue</TableCell>
            <TableCell align="left">Retained Value</TableCell>
            <TableCell align="left">Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              className={selectedRow === index ? 'selected-row' : ''}
            >
                
                <TableCell component="th" scope="row">
                  <Button size="small" color="primary" 
                    onClick={() => {setOpen(true);setCurrentId(item._id);handleRowClick(index)}} 
                    
                    >
                    <EditIcon fontSize="small" />
                  </Button>

                  



                  </TableCell>
                <TableCell component="th" scope="row">{item.saleItem}</TableCell>
                <TableCell align="left">{item.saleItemType}</TableCell>
                <TableCell align="right">{item.price}</TableCell>
                <TableCell align="center">{item.priceType}</TableCell>
                <TableCell align="center">{item.cost}</TableCell>
                <TableCell align="center">{item.costType}</TableCell>
                <TableCell align="center">{item.estimatedDuration}</TableCell>
                <TableCell align="center">{item.qualifyingRevenue}</TableCell>
                <TableCell align="center">{item.retainedValue}</TableCell>
                <TableCell align="center">
                  <Button size="small" color="primary" onClick={() => {dispatch(deleteItem(item._id))}} >
                    <DeleteIcon fontSize="small" />
                  </Button>
                </TableCell>
                
            </TableRow>
            
             ))}
             
        </TableBody>
           
      </Table>
    </TableContainer>
    <Dialog
        style={{ backgroundColor: 'transparent'}}
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        sx={{width: '100%'}}
      >
        <DialogTitle id="scroll-dialog-title">Edit Sale Item</DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          
            <Items currentId={currentId} setCurrentId={setCurrentId}/>
          
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    
    </Grid>
    )
  }
  export default SaleTable