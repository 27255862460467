import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { getQuotes, deleteQuote} from '../../actions/quotes';
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  Dialog , DialogActions ,DialogContent, DialogTitle,   } from '@mui/material';
  
  import Box from '@mui/material/Box';
  import Modal from '@mui/material/Modal';
  import Fade from '@mui/material/Fade';
  import Button from '@mui/material/Button';
  import Typography from '@mui/material/Typography';

import DeleteIcon from '@mui/icons-material/Delete';
import PreviewIcon from '@mui/icons-material/Preview';
import EditIcon from '@mui/icons-material/Edit';
import Items from '../Form/Item'
import AddIcon from '@mui/icons-material/Add';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '400',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 2,
  p: 4,
};

const MyTable = ({ quote }) => {
  return (
    <TableCell align="center">
      {quote.saleItem.map((item, index) => (
        <React.Fragment key={index}>
          {item}
          <br /> {/* Displaying each item on a new line */}
        </React.Fragment>
      ))}
    </TableCell>
  );
};

const QuoteTable = ({isAdmin}) => {     
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const {quotes} = useSelector((state) => state.quotes);

  const profile = JSON.parse(localStorage.getItem('profile'))
    const email = 'eglot@gmail.com' //profile?.result.email

  const filteredQuotes = quotes.filter(quote => quote.email === email);

  console.log('filteredQuotes', filteredQuotes)


  useEffect(() => {
    dispatch(getQuotes(email));
  }, []);


  return (
    <Grid item sx={{m:1, width:'98%'}}>
      <h1 className='text-xl uppercase font-semibold text-slate-500'>Quote Generated</h1>
      <TableContainer component={Paper} className="table">
      <Table sx={{ minWidth: 400 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Quote #</TableCell>
            <TableCell align="center">Company Name</TableCell>
            <TableCell align="center">Company Details</TableCell>
            <TableCell align="center">Client Requester Name</TableCell>
            <TableCell align="center">Sale Item</TableCell>
            <TableCell align="center">Date Created</TableCell>

            <TableCell align="center">View</TableCell>
            <TableCell align="center">Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {quotes.map((quote) => (
            <TableRow
              key={quote._id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                
                <TableCell component="th" scope="row">{quote.quoteNumber}</TableCell>
                <TableCell align="center">{quote.companyName}</TableCell>
                <TableCell align="center">{quote.companyDetails}</TableCell>
                <TableCell align="center">{quote.clientRequesterName}</TableCell>
                <TableCell>
                  {quote.saleItem.map((item, index) => (
                    <Typography key={index} align="center">{item.saleItem}</Typography>
                  ))}
                </TableCell>
                <TableCell align="center">{quote.createdAt.split('T')[0]}</TableCell>

                <TableCell align="center">
                  <Button size="small" color="primary" onClick={() => {navigate('/viewQuoteAdmin/', {state: {id: quote._id}})}} 
                  >
                  <PreviewIcon fontSize="small" />
                  </Button>
                </TableCell>

                <TableCell align="center">
                  <Button size="small" color="primary" onClick={() => {dispatch(deleteQuote(quote._id))}} >
                    <DeleteIcon fontSize="small" />
                  </Button>
                </TableCell>
                
            </TableRow>
            
             ))}
             
        </TableBody>
           
      </Table>
    </TableContainer>
    
    </Grid>
    )
  }
  export default QuoteTable