import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Container,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { createQuote } from '../../actions/quotes';
import { getItems } from '../../actions/items';
import { formatPhoneNumber, isValidEmail } from './helpers'; // Create a helpers.js file for helper functions
import SelectQuote from './SelectQuote';
import Items from '../Form/EditItem';
import EditSaleItem from '../Form/EditSaleItem';
import PhoneNumberInput from './PhoneNumberInput';
import Tester from './Tester';

import './styles.css'

const delay = ms => new Promise(
  resolve => setTimeout(resolve, ms)
);

const ITEM_HEIGHT = 100;
const ITEM_PADDING_TOP = 2;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 3.5 + ITEM_PADDING_TOP,
      width: 450,
    },
  },
};


const CreateQuote = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate ()
  const items = useSelector((state) => state.items);
  const [saleItem, setSaleItem] = useState([]);
  const [selectedItemsData, setSelectedItemsData] = useState([]);
  const [editingIndex, setEditingIndex] = useState();
  console.log("Selected Items: ", selectedItemsData)
  const [isEditing, setIsEditing] = useState(true);
  const [isDivVisible, setIsDivVisible] = useState(false)
  const [quoteData, setQuoteData] = useState({ companyName: " ", companyDetails: " ", clientRequesterName: " ", saleItem: " ", address: " ", email: " ", phone: " ", uniqueID: Math.random()});
  
  useEffect(() => {
    dispatch(getItems());
  }, [dispatch]);

  const handleChangeSaleItem = (event) => {
    const {target: { value },}  = event;
    setSaleItem(typeof value === 'string' ? value.split(',') : value,); 
    const selectedItemsData = value.map(selectedSaleItem => {
      const selectedItem = items.find(item => item.saleItem === selectedSaleItem);
      return selectedItem;
    });
    setSelectedItemsData(selectedItemsData);
    if (value.length>=1){
      setIsDivVisible(true);
      setQuoteData(prevQuoteData => ({...prevQuoteData, saleItem: selectedItemsData}));
    }
    else{   
      setIsDivVisible(false);
    }
  }

  const handlePhoneChange = (e) => {
    const formattedPhone = formatPhoneNumber(e.target.value);
    setQuoteData({ ...quoteData, phone: formattedPhone });
  };

  const [emailError, setEmailError] = useState('');

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setQuoteData({ ...quoteData, email });

    if (email && !isValidEmail(email)) {
      setEmailError('Invalid email format');
    } else {
      setEmailError('');
    }
  };

  const handleSubmitQuote = async (e) => {
    e.preventDefault();
    dispatch(createQuote(quoteData));
    await delay(300);
    navigate('/viewQuote/', {state: {uniqueID: quoteData.uniqueID}})
  };

  const clear = () => {
    setQuoteData({ companyName: '', companyDetails: '', clientRequesterName: '', uniqueID: Math.random(), address: '', email: '', phone: '', saleItem: ''});
    setSelectedItemsData([]);
    setIsDivVisible(false);
  };

  return (
    <div>
    <h1 className='text-xl uppercase font-semibold text-slate-500'>Create New Quote</h1>
    <form className='form' autoComplete="off" noValidate onSubmit={handleSubmitQuote}>
      <Grid container spacing={1}>
        <Grid item xs={2} md={3}></Grid>
        <Grid item xs={12} md={6} justifyContent='center'>
        <TextField
          required
          label="Company Name"
          id="outlined-start-adornment"
          sx={{ m: 1, width:"95%" }}
          value ={quoteData.companyName}
          onChange={(e) => setQuoteData({ ...quoteData, companyName: e.target.value })}
        />
        <TextField
          required
          label="Company Details"
          id="outlined-start-adornment"
          sx={{ m: 1,width:"95%" }}
          value ={quoteData.companyDetails}
          onChange={(e) => setQuoteData({ ...quoteData, companyDetails: e.target.value })}
        />
        <TextField
          required
          label="Client Requester Name"
          id="outlined-start-adornment"
          sx={{ m: 1,width:"95%"}}
          value ={quoteData.clientRequesterName}
          onChange={(e) => setQuoteData({ ...quoteData, clientRequesterName: e.target.value })}
        />
        <TextField
          required
          label="Address"
          id="outlined-start-adornment"
          sx={{ m: 1,width:"95%"}}
          value ={quoteData.address}
          onChange={(e) => setQuoteData({ ...quoteData, address: e.target.value })}
        />
        <TextField
          required
          label="Email"
          id="outlined-start-adornment"
          sx={{ m: 1,width:"95%"}}
          value ={quoteData.email}gggg
          onChange={handleEmailChange}
          error={!!emailError}
          helperText={emailError}
        />
        <TextField
          required
          label="Phone Number"
          id="outlined-start-adornment"
          sx={{ m: 1,width:"95%"}}
          value ={quoteData.phone}
          onChange={handlePhoneChange}
        />      
      
        <FormControl sx={{m: 1, width: "95%" }}>
        
        <InputLabel sx={{}}>Sale Item</InputLabel>
        
        <Select
          required
          multiple
          value={saleItem}
          onChange={(e)=> {handleChangeSaleItem(e, items._id)}}
          input={<OutlinedInput label="Sale Item" />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
        >
          {items.map((item) => (

            <MenuItem key={item._id} value={item.saleItem}>
              <Checkbox checked={saleItem.indexOf(item.saleItem) > -1 }/>
              <ListItemText primaryTypographyProps={{component: 'div', sx: { whiteSpace: 'normal' }}}
                primary={item.saleItem}
              />          
            </MenuItem>
          ))}
        </Select>

      </FormControl>
      
      {isDivVisible && (
        <Grid  item xs={12} md={12}>
          {selectedItemsData.map((selectedItem, index) => (
            <div key={index}>
              {isEditing && index === editingIndex ? (
                <div>
                  <div>_____________________________________________</div>
                  <Typography mb={2} mt={2}>{index+1}. {selectedItem.saleItem}</Typography>
                  <Button mt={2} variant="contained" color="secondary" size="small"
                    onClick={() => setEditingIndex(null)} // Close the editing view
                  >Cancel Customize</Button>
            
                  
                
                  <EditSaleItem
                    item={selectedItem}
                    onSave={(editedItem) => {
                      setQuoteData((prevData) => ({
                        ...prevData,
                        saleItem: editedItem,
                      }));

                      const updatedSelectedItemsData = [...selectedItemsData];
                      updatedSelectedItemsData[index] = editedItem;
                      setSelectedItemsData(updatedSelectedItemsData);

                      setEditingIndex(null); 
                    }}
                  />
                </div>         
              ) : (
                <div>
                  <Typography mb={2} mt={2}>{index+1}. {selectedItem.saleItem}</Typography>
                  <Button variant="contained" color="primary" size="small"
                    onClick={() => setEditingIndex(index)} 
                  >Edit</Button>
                </div>
              )}
            </div>
          ))}
        </Grid>
      )}

      <Grid container  direction="column" alignItems="center" justifyContent="center">
      <Grid item xs={2} md={2}>
      
      </Grid>
      <Grid sx={{ mt: 5, mb: 5 }} xs={12} md={12} justifyContent='space-between'>
   
      <Button sx={{m:1}} 
        variant="contained" 
        color="primary" 
        size="large" 
        type="submit"
        onClick={() => {handleSubmitQuote()}}
        >Get Quote
      </Button>
   
      <Button sx={{m:1}} 
        variant="contained" 
        color="error" 
        size="large" 
        onClick={() => {clear()}}
        >Clear
      </Button>
      </Grid>
      <Grid item xs={2} md={2}></Grid>
      </Grid>
          </Grid>
        <Grid item xs={2} md={3}>
        </Grid>
      </Grid>
    </form>
    </div>

  );
}

export default CreateQuote