import { FETCH_ALL_ITEM, FETCH_ITEM, CREATE_ITEM, UPDATE_ITEM, DELETE_ITEM } from '../constants/actionTypes';

import * as api from '../api/index.js';

export const getItems = () => async (dispatch) => {
  try {
    const { data } = await api.fetchItems();
    data.sort(function(a,b) {
      return (b.saleItem > a.saleItem) ? -1 : ((a.saleItem > b.saleItem) ? 0 : -1);
   });
    dispatch({ type: FETCH_ALL_ITEM, payload: data });
  } catch (error) {
    console.log(error.message);
  }
};

export const getItem = (name) => async (dispatch) => {

    try {
      const { data } = await api.fetchItem(name);
      dispatch({ type: FETCH_ITEM, payload: data });  
    
    } catch (error) {
      console.log(error.message);
    }
  };

export const createItem = (item) => async (dispatch) => {
  try {
    const { data } = await api.createItem(item);
    dispatch({ type: CREATE_ITEM, payload: data });    
  } catch (error) {
    console.log(error.message);
  }
};

export const updateItem = (id, item) => async (dispatch) => {
  try {
    const { data } = await api.updateItem(id, item);
    dispatch({ type: UPDATE_ITEM, payload: data });
  } catch (error) {
    console.log(error.message);
  }
};

export const deleteItem = (id) => async (dispatch) => {
  try {
    await api.deleteItem(id);
    dispatch({ type: DELETE_ITEM, payload: id });
  } catch (error) {
    console.log(error.message);
  }
};

