import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import './styles.css'

import { createItem, updateItem } from '../../actions/items';
import { Typography } from '@mui/material';

const Item = ({ currentId, setCurrentId }) => {
  const [itemData, setItemData] = useState({ 
    saleItem: '', 
    saleItemType: '', 
    price: '', 
    priceType: '', 
    cost: '', 
    costType: '', 
    estimatedDuration: '', 
    qualifyingRevenue: '', 
    retainedValue: '',
    description: '',
    objectives: '',
    inScope: '',
    outOfScope: '',
    dataCollection: '',
    interviews: '',
    assessment: '',
    costActDel:'',
    terms: '',
    customerRes: '',
    travelReq: '',
    miscReq: ''
  });
  const item = useSelector((state) => (currentId ? state.items.find((item) => item._id === currentId) : null));
  const dispatch = useDispatch();

  console.log(currentId)

  useEffect(() => {
    if (item) setItemData(item);
  }, [item]);

  const clear = () => {
    setCurrentId(0);
    setItemData({ saleItem: '', 
                  saleItemType: '', 
                  price: '', 
                  priceType: '', 
                  cost: '', 
                  costType: '', 
                  estimatedDuration: '', 
                  qualifyingRevenue: '', 
                  retainedValue: '',
                  description: '',
                  objectives: '',
                  inScope: '',
                  outOfScope: '',
                  dataCollection: '',
                  interviews: '',
                  assessment: '',
                  costActDel:'',
                  terms: '',
                  customerRes: '',
                  travelReq: '',
                  miscReq: '' });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (currentId === 0) {
      dispatch(createItem(itemData));
      console.log('The data is save!');
      alert("The Sale Item has been added to the database!")
      clear();
    } else {
      dispatch(updateItem(currentId, itemData));
      clear();
    }
  };

  return (
    <div >
    
    <form className='form' autoComplete="off" noValidate onSubmit={handleSubmit}>
      <Container >
        <Grid container xs={12} sm={12} spacing={1}>
        <Grid item xs={12} sm={8}>
        <TextField
          label="Sale Item"
          id="outlined-start-adornment"
          sx={{ m: 1, width: '100%' }}
          value={itemData.saleItem}
          onChange={(e) => setItemData({ ...itemData, saleItem: e.target.value })}
        />
        </Grid>
        <Grid item xs={6} sm={4}>
        <FormControl sx={{ m: 1, width: "100%" }}>
          <InputLabel >Sale Item Type</InputLabel>
            <Select
              value={itemData.saleItemType}
              label="Sale Item Type"
              onChange={(e) => setItemData({ ...itemData, saleItemType: e.target.value })}
            >
              <MenuItem value="Services">Services</MenuItem>
              <MenuItem value="Subscription">Subscription</MenuItem>
            </Select>
        </FormControl>
        </Grid>
        </Grid>

        <Grid container xs={12} sm={12} spacing={1}>

        <Grid className='price' item xs={12} sm={6}>  
        <FormControl fullWidth sx={{ m: 2, width: '20ch' }} variant="standard">
          <InputLabel htmlFor="standard-adornment-amount">Price</InputLabel>
          <Input
            type="number"
            id="standard-adornment-amount"
            value={itemData.price}
            onChange={(e) => setItemData({ ...itemData, price: e.target.value })}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
          />
        </FormControl>

        <FormControl sx={{ m: 1, width: '20ch' }}>
          <InputLabel >Price Type</InputLabel>
            <Select
              value={itemData.priceType}
              label="Price Type"
              onChange={(e) => setItemData({ ...itemData, priceType: e.target.value })}
              >
              <MenuItem value="Day">Day</MenuItem>
              <MenuItem value="Device Month">Device Month</MenuItem>
              <MenuItem value="Month">Month</MenuItem>
            </Select>
        </FormControl>

        </Grid>

        

        <Grid className='price' item xs={12} sm={6}>  
        <FormControl fullWidth sx={{ m: 2, width: '20ch' }} variant="standard">
          <InputLabel htmlFor="standard-adornment-amount">Cost</InputLabel>
          <Input
            type="number"
            id="standard-adornment-amount"
            value={itemData.cost}
            onChange={(e) => setItemData({ ...itemData, cost: e.target.value })}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
          />
        </FormControl>
        
        <FormControl sx={{ m: 1, width: '20ch' }}>
          <InputLabel >Cost Type</InputLabel>
            <Select
              value={itemData.costType}
              label="Cost Type"
              onChange={(e) => setItemData({ ...itemData, costType: e.target.value })}
              >
              <MenuItem value="Day">Day</MenuItem>
              <MenuItem value="Device Month">Device Month</MenuItem>
              <MenuItem value="Month">Month</MenuItem>
            </Select>
        </FormControl>
        </Grid>
        

        <Grid className='price' item xs={12} md={3}>  
        <FormControl variant="standard" sx={{ m: 2, width: '20ch' }}>
            <Input
              type="number"
              value={itemData.estimatedDuration}
              onChange={(e) => setItemData({ ...itemData, estimatedDuration: e.target.value })}
              endAdornment={<InputAdornment position="end">Days</InputAdornment>}
            />
          <FormHelperText>Estimated Duration</FormHelperText>
        </FormControl>
      </Grid>

        <Grid className='price' item xs={12} md={3}>  
        <FormControl fullWidth sx={{ m: 2, width: '20ch' }} variant="standard">
          <InputLabel htmlFor="standard-adornment-amount">Qualifying Revenue</InputLabel>
          <Input
            type="number"
            id="standard-adornment-amount"
            value={itemData.qualifyingRevenue}
            onChange={(e) => setItemData({ ...itemData, qualifyingRevenue: e.target.value })}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
          />
        </FormControl>
        </Grid>
        <Grid className='price' item xs={12} md={3}>  
        <FormControl fullWidth sx={{ m: 2, width: '20ch' }} variant="standard">
          <InputLabel htmlFor="standard-adornment-amount">Retained Value</InputLabel>
          <Input
            type="number"
            id="standard-adornment-amount"
            value={itemData.retainedValue}
            onChange={(e) => setItemData({ ...itemData, retainedValue: e.target.value })}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
          />
        </FormControl>
        </Grid>
        <TextField
          label="Description of the Solution"
          multiline
          maxRows={Infinity}
          sx={{ m: 1, width: '100%' }}
          value={itemData.description}
          onChange={(e) => setItemData({ ...itemData, description: e.target.value })}
        />
        <TextField
          label="High Level Objectives"
          multiline
          maxRows={Infinity}
          sx={{ m: 1, width: '100%' }}
          value={itemData.objectives}
          onChange={(e) => setItemData({ ...itemData, objectives: e.target.value })}
        />
        <Typography>Scope</Typography>
        <TextField
          label="In Scope"
          multiline
          maxRows={Infinity}
          sx={{ m: 1, width: '100%' }}
          value={itemData.inScope}
          onChange={(e) => setItemData({ ...itemData, inScope: e.target.value })}
        />
        <TextField
          label="Out of Scope"
          multiline
          maxRows={Infinity}
          sx={{ m: 1, width: '100%' }}
          value={itemData.outOfScope}
          onChange={(e) => setItemData({ ...itemData, outOfScope: e.target.value })}
        />
        <Typography>Approach</Typography>
        <TextField
          label="Data Collection"
          multiline
          maxRows={Infinity}
          sx={{ m: 1, width: '100%' }}
          value={itemData.dataCollection}
          onChange={(e) => setItemData({ ...itemData, dataCollection: e.target.value })}
        />
        <TextField
          label="Interviews "
          multiline
          maxRows={Infinity}
          sx={{ m: 1, width: '100%' }}
          value={itemData.interviews}
          onChange={(e) => setItemData({ ...itemData, interviews: e.target.value })}
        />
        <TextField
          label="Assessment"
          multiline
          maxRows={Infinity}
          sx={{ m: 1, width: '100%' }}
          value={itemData.assessment}
          onChange={(e) => setItemData({ ...itemData, assessment: e.target.value })}
        />
        <Typography>Payments</Typography>
        <TextField
          label="Cost Activities/Deliverables"
          multiline
          maxRows={Infinity}
          sx={{ m: 1, width: '100%' }}
          value={itemData.costActDel}
          onChange={(e) => setItemData({ ...itemData, costActDel: e.target.value })}
        />
        <TextField
          label="Terms"
          multiline
          maxRows={Infinity}
          sx={{ m: 1, width: '100%' }}
          value={itemData.terms}
          onChange={(e) => setItemData({ ...itemData, terms: e.target.value })}
        />
        <TextField
          label="Customer Responsibilities"
          multiline
          maxRows={Infinity}
          sx={{ m: 1, width: '100%' }}
          value={itemData.customerRes}
          onChange={(e) => setItemData({ ...itemData, customerRes: e.target.value })}
        />
        <TextField
          label="*Travel Requirements"
          multiline
          maxRows={Infinity}
          sx={{ m: 1, width: '100%' }}
          value={itemData.travelReq}
          onChange={(e) => setItemData({ ...itemData, travelReq: e.target.value })}
        />
        <TextField
          label="Miscellaneous Requirements"
          multiline
          maxRows={Infinity}
          sx={{ m: 1, width: '100%' }}
          value={itemData.miscReq}
          onChange={(e) => setItemData({ ...itemData, miscReq: e.target.value })}
        />
        </Grid>
        
      
      <Grid className='button' item xs={12} sm={12}>
        <Button sx={{ m: 1,}} variant="contained" color="primary" size="large" type="save">Save</Button>
        <Button sx={{ m: 1,}} variant="contained" color="error" size="large" onClick={clear}>Clear</Button>
      </Grid>  
        </Container>
    </form>
    </div>
  );
};

export default Item;
