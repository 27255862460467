// EditSaleItem component

import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import { Grid } from '@mui/material';

function EditSaleItemProposal({ item, onSave }) {
  const [editedItem, setEditedItem] = useState(item);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setEditedItem((prevItem) => ({
      ...prevItem,
      [name]: value,
    }));
  };

  const handleSave = () => {
    onSave(editedItem); // Pass the edited data back to the parent component
  };

  return (
    <Grid container xs={12} md={8}>
        <FormControl sx={{ m: 1, width: "30%" }}>
            <InputLabel >Sale Item Type</InputLabel>
                <Select
                    label="Sale Item Type"
                    name="saleItemType"
                    value={editedItem.saleItemType}
                    onChange={handleChange}
                >
                    <MenuItem value="Services">Services</MenuItem>
                    <MenuItem value="Subscription">Subscription</MenuItem>
                </Select>
        </FormControl>

        <FormControl fullWidth sx={{ m: 1, width: '20ch' }} variant="standard">
            <InputLabel htmlFor="standard-adornment-amount">Price</InputLabel>
            <Input
                type="number"
                name="price"
                id="standard-adornment-amount"
                value={editedItem.price}
                onChange={handleChange}
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
            />
        </FormControl>

        <FormControl sx={{ m: 1, width: '20ch' }}>
            <InputLabel >Price Type</InputLabel>
                <Select
                    value={editedItem.priceType}
                    label="Price Type"
                    name="priceType"
                    onChange={handleChange}
                >
                    <MenuItem value="Day">Day</MenuItem>
                    <MenuItem value="Device Month">Device Month</MenuItem>
                    <MenuItem value="Month">Month</MenuItem>
                </Select>
        </FormControl>

        <FormControl variant="standard" sx={{ m: 1, width: '20ch' }}>
            <Input
                type="number"
                name="estimatedDuration"
                value={editedItem.estimatedDuration}
                onChange={handleChange}
                endAdornment={<InputAdornment position="end">Days</InputAdornment>}
            />
            <FormHelperText>Estimated Duration</FormHelperText>
        </FormControl>

        <TextField
            label="Description of the Solution"
            multiline
            name="description"
            maxRows={Infinity}
            sx={{ m: 1, width: '100%' }}
            value={editedItem.description}
            onChange={handleChange}
        />

        <TextField
            label="High Level Objectives"
            multiline
            name="objectives"
            maxRows={Infinity}
            sx={{ m: 1, width: '100%' }}
            value={editedItem.objectives}
            onChange={handleChange}
        />

        <TextField
            label="In Scope"
            multiline
            name="inScope"
            maxRows={Infinity}
            sx={{ m: 1, width: '100%' }}
            value={editedItem.inScope}
            onChange={handleChange}
        />

        <TextField
            label="Out of Scope"
            multiline
            name="outOfScope"
            maxRows={Infinity}
            sx={{ m: 1, width: '100%' }}
            value={editedItem.outOfScope}
            onChange={handleChange}
        />
        <TextField
            label="Data Collection"
            multiline
            name="dataCollection"
            maxRows={Infinity}
            sx={{ m: 1, width: '100%' }}
            value={editedItem.dataCollection}
            onChange={handleChange}
        />
        <TextField
            label="Interviews"
            multiline
            name="interviews"
            maxRows={Infinity}
            sx={{ m: 1, width: '100%' }}
            value={editedItem.interviews}
            onChange={handleChange}
        />
        <TextField
            label="Assessment"
            multiline
            name="assessment"
            maxRows={Infinity}
            sx={{ m: 1, width: '100%' }}
            value={editedItem.assessment}
            onChange={handleChange}
        />
        <TextField
            label="Cost Activities / Deliverables"
            multiline
            name="costActDel"
            maxRows={Infinity}
            sx={{ m: 1, width: '100%' }}
            value={editedItem.costActDel}
            onChange={handleChange}
        />
        <TextField
            label="Customer Responsibilities"
            multiline
            name="customerRes"
            maxRows={Infinity}
            sx={{ m: 1, width: '100%' }}
            value={editedItem.customerRes}
            onChange={handleChange}
        />
        <TextField
            label="Travel Requirements"
            multiline
            name="travelReq"
            maxRows={Infinity}
            sx={{ m: 1, width: '100%' }}
            value={editedItem.travelReq}
            onChange={handleChange}
        />
        <TextField
            label="Miscellaneous Requirements"
            multiline
            name="miscReq"
            maxRows={Infinity}
            sx={{ m: 1, width: '100%' }}
            value={editedItem.miscReq}
            onChange={handleChange}
        />
        <TextField
            label="Terms"
            multiline
            name="terms"
            maxRows={Infinity}
            sx={{ m: 1, width: '100%' }}
            value={editedItem.terms}
            onChange={handleChange}
        />

        <Button variant="contained" size='small' color="primary" onClick={handleSave}>
            Save Changes
        </Button>
    </Grid>
  );
}

export default EditSaleItemProposal;
