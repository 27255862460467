import React, { useEffect, useState } from 'react'
import { CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route, useLocation } from 'react-router-dom';
import { PDFViewer } from '@react-pdf/renderer';
import Proposal from "./Proposal"
import { getProposal } from '../../../actions/proposals';
import './styles.css'

const PDFViewAdmin = () => {
  const location = useLocation();
  const id = location.state.id;

  const dispatch = useDispatch()
  const {proposal, proposals, isLoading} = useSelector((state) => state.proposals)
  useEffect(() => {   
    dispatch(getProposal(id));  
  }, [id, dispatch]);

  if(!proposal) return  <div>Loading</div>

    return(
  
        <div>
        <PDFViewer className="pdf">
           <Proposal proposal={proposal}/>
        </PDFViewer>
        </div>

    )
}

export default PDFViewAdmin