export const isValidEmail = (email) => {
    // Regular expression for email format validation
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };
  
 export const formatPhoneNumber = (phoneNumber) => {
    // Remove all non-digit characters from the input
    const cleanedPhoneNumber = phoneNumber.replace(/\D/g, '');
  
    // Apply the desired phone number format
    const match = cleanedPhoneNumber.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
  
    // Return the original value if it doesn't match the expected format
    return phoneNumber;
  };

  export const letterData = {
    id: 1,
    content: "CMS Consulting, Inc. is pleased to propose to the {Customer Company Name} (Customer) a team of Security Analysts (SA). They will provide an IT needs analysis and security assessment with respect to the current state of the Customer infrastructure. \n\nOur highly experienced professional consultants have the requisite skills and expertise in security and IT to make this important initiative a success.  They have availability starting immediately and are offered at a fixed price as described below.",
  };

  export const backgroundData = {
    id: 1,
    content: "Recently the CUSTOMER team has made changes to their infrastructure based on a security assessment performed in the recent past. CUSTOMER now requires a new assessment to ensure that their security posture is not compromised.",
  };

  export const proposalData = {
    id: 1,
    content: "The following proposal identifies some of the activities and deliverables in which CMS Consulting can provide CUSTOMER, with reference to the IT Needs Assessment and Analysis.",
  };