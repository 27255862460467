import React, { useEffect, useState } from 'react'
import { CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route, useLocation } from 'react-router-dom';
import { PDFViewer } from '@react-pdf/renderer';
import Quote from "./Quote"
import { getNew, getQuotes } from '../../actions/quotes';
import './styles.css'

const PDFView = () => {
  const location = useLocation();
  const uniqueID = location.state.uniqueID;

  console.log(uniqueID)

  const dispatch = useDispatch()
  const { quotes, isLoading} = useSelector((state) => state.quotes[0])

  useEffect(() => { 
  
    dispatch(getQuotes()); 
  }, []);

  //useEffect(() => { 
  //
  //  dispatch(getNew(uniqueID)); 
 // }, [uniqueID, dispatch]);

  console.log(quotes)

  if(!quotes) return  <div>Loading</div>

    return(
  
        <div>
        <PDFViewer className="pdf">
            <Quote quote={quotes}/>
        </PDFViewer>
        </div>

    )
}

export default PDFView