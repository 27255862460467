import { START_LOADING, END_LOADING, FETCH_ALL_PROPOSAL, FETCH_PROPOSAL,FETCH_NEW_PROPOSAL, CREATE_PROPOSAL, UPDATE_PROPOSAL, DELETE_PROPOSAL } from '../constants/actionTypes';

import * as api from '../api/index.js';


export const getProposals = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.fetchProposals();

    data.sort(function(b,a) {
      return (a.proposalNumber > b.proposalNumber) ? 1 : ((b.proposalNumber > a.proposalNumber) ? -1 : 0);
   });
    dispatch({ type: FETCH_ALL_PROPOSAL, payload: data });
    dispatch({ type: END_LOADING });

    console.log(data)
  } catch (error) {
    console.log(error.message);
  }
};

export const getProposal = (id) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });

    const { data } = await api.fetchProposal(id);
    dispatch({ type: FETCH_PROPOSAL, payload: { proposal: data } });  
  
  } catch (error) {
    console.log(error.message);
  }
};

export const getNewProposal = (uniqueID) => async (dispatch) => {

  const it = String(uniqueID)

  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.fetchProposal();
    const filter = data.find((data)=> data.uniqueID===it)

    dispatch({ type: FETCH_ALL_PROPOSAL, payload: filter });
    dispatch({ type: END_LOADING });
    console.log(data)
    console.log(filter)
    console.log(it)
  } catch (error) {
    console.log(error.message);
  }
};

export const createProposal = (proposal) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.createProposal(proposal);
    dispatch({ type: CREATE_PROPOSAL, payload: data });

   // navigate(`/posts/${data._id}`);
   
  } catch (error) {
    console.log(error.message);
  }
};

export const deleteProposal = (id) => async (dispatch) => {
  try {
    await api.deleteProposal(id);
    dispatch({ type: DELETE_PROPOSAL, payload: id });
  } catch (error) {
    console.log(error.message);
  }
};
