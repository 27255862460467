import { FETCH_ALL_PROPOSAL, FETCH_PROPOSAL, FETCH_NEW_PROPOSAL, CREATE_PROPOSAL, UPDATE_PROPOSAL, DELETE_PROPOSAL } from '../constants/actionTypes';

export default (state = { isLoading: true, proposals: [] }, action) => {
  switch (action.type) {
    case 'START_LOADING':
      return { ...state, isLoading: true };
    case 'END_LOADING':
      return { ...state, isLoading: false };
    case FETCH_ALL_PROPOSAL:
      return {
        ...state,
        proposals: action.payload
      };
    case FETCH_PROPOSAL:
      return { ...state, proposal: action.payload.proposal };
    case FETCH_NEW_PROPOSAL:
      return { ...state, proposal: action.payload.proposal };
    case CREATE_PROPOSAL:
      return { ...state, proposals: [...state.proposals, action.payload] };
    case UPDATE_PROPOSAL:
      return { ...state, proposals: state.proposals.map((proposal) => (proposal._id === action.payload._id ? action.payload : proposal)) };
    case DELETE_PROPOSAL:
      return { ...state, proposals: state.proposals.filter((proposal) => proposal._id !== action.payload) };
    default:
      return state;
  }
};
