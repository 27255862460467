import { FETCH_ALL_QUOTE, FETCH_QUOTE,FETCH_NEW, CREATE_QUOTE, UPDATE_QUOTE, DELETE_QUOTE } from '../constants/actionTypes';

export default (state = { isLoading: true, quotes: [] }, action) => {
  switch (action.type) {
    case 'START_LOADING':
      return { ...state, isLoading: true };
    case 'END_LOADING':
      return { ...state, isLoading: false };
    case FETCH_ALL_QUOTE:
      return {
        ...state,
        quotes: action.payload
      };
    case FETCH_QUOTE:
      return { ...state, quote: action.payload.quote };
    case FETCH_NEW:
      return { ...state, quote: action.payload.quote };
    case CREATE_QUOTE:
      return { ...state, quotes: [...state.quotes, action.payload] };
    case UPDATE_QUOTE:
      return { ...state, quotes: state.quotes.map((quote) => (quote._id === action.payload._id ? action.payload : quote)) };
    case DELETE_QUOTE:
      return { ...state, quotes: state.quotes.filter((quote) => quote._id !== action.payload) };
    default:
      return state;
  }
};
