import React, {useEffect, useState} from 'react'
import { useNavigate  } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { useDispatch, useSelector } from 'react-redux';
import { createQuote} from '../../actions/quotes';
import { createProposal} from '../../actions/proposals';
import SelectQuote from './SelectQuote'
import { getItems, getItem} from '../../actions/items';
import { getNew, } from '../../actions/quotes';
import Grid from '@mui/material/Grid';
import Input from '@mui/material/Input';
import Typography from '@mui/material/Typography';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import { formatPhoneNumber, isValidEmail, letterData, backgroundData, proposalData } from './helpers';
import EditSaleItem from '../Form/EditSaleItemProposal';

import './styles.css'

const delay = ms => new Promise(
  resolve => setTimeout(resolve, ms)
);

const ITEM_HEIGHT = 100;
const ITEM_PADDING_TOP = 2;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CreateProposal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate ()
  const items = useSelector((state) => state.items);
  const [saleItem, setSaleItem] = useState([]);
  const [selectedItemsData, setSelectedItemsData] = useState([]);
  const [editingIndex, setEditingIndex] = useState();
  console.log("Selected Items: ", selectedItemsData)
  const [isEditing, setIsEditing] = useState(true);
  const [isDivVisible, setIsDivVisible] = useState(false)

  const [editedLetter, setEditedLetter] = useState(letterData.content);
  const [editedBackground, setEditedBackground] = useState(backgroundData.content);
  const [editedProposal, setEditedProposal] = useState(proposalData.content);
  
  const [quoteData, setQuoteData] = useState({ companyName: " ", companyDetails: " ", clientRequesterName: " ", saleItem: " ", address: " ", email: " ", phone: " ", 
    letter: editedLetter,
    background: editedBackground,
    proposal: editedProposal, 
    uniqueID: Math.random()});
  
    const useToggleEdit = (initialState) => {
      const [isEditing, setIsEditing] = useState(initialState);
    
      const handleToggleEdit = () => {
        setIsEditing(!isEditing);
      };
    
      return [isEditing, handleToggleEdit];
    };
    
    // Usage
    const [isEditingLetter, handleToggleEditLetter] = useToggleEdit(false);
    const [isEditingBackground, handleToggleEditBackground] = useToggleEdit(false);
    const [isEditingProposal, handleToggleEditProposal] = useToggleEdit(false);

  useEffect(() => {
    dispatch(getItems());
  }, [dispatch]);

  const handleChangeSaleItem = (event) => {
    const {target: { value },}  = event;
    setSaleItem(typeof value === 'string' ? value.split(',') : value,); 
    const selectedItemsData = value.map(selectedSaleItem => {
      const selectedItem = items.find(item => item.saleItem === selectedSaleItem);
      return selectedItem;
    });
    setSelectedItemsData(selectedItemsData);
    if (value.length>=1){
      setIsDivVisible(true);
      setQuoteData(prevQuoteData => ({...prevQuoteData, saleItem: selectedItemsData}));
    }
    else{   
      setIsDivVisible(false);
    }
  }

  const handlePhoneChange = (e) => {
    const formattedPhone = formatPhoneNumber(e.target.value);
    setQuoteData({ ...quoteData, phone: formattedPhone });
  };

  const [emailError, setEmailError] = useState('');

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setQuoteData({ ...quoteData, email });

    if (email && !isValidEmail(email)) {
      setEmailError('Invalid email format');
    } else {
      setEmailError('');
    }
  };

  const handleSubmitProposal = async (e) => {
    e.preventDefault();   
    dispatch(createProposal(quoteData));
    await delay(300);
    navigate('/viewProposal/', {state: {uniqueID: quoteData.uniqueID}})
  };

  const clear = () => {
    setQuoteData({ companyName: '', companyDetails: '', clientRequesterName: '', uniqueID: Math.random(), address: '', email: '', phone: '', saleItem: ''});
    setSelectedItemsData([]);
    setIsDivVisible(false);
    setSaleItem([]);
    setEditedLetter(editedLetter)
  };

  return (
    <div>
    <h1 className='text-xl uppercase font-semibold text-slate-500'>Create New Proposal</h1>
    <form className='form' autoComplete="off"  onSubmit={handleSubmitProposal}>
     
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }} direction="row" justifyContent="center" alignItems="center">
          <Grid item xs={12} md={6}>
            <TextField
              required
              label="Company Name"
              id="outlined-start-adornment"
              sx={{width:"100%" }}
              value ={quoteData.companyName}
              onChange={(e) => setQuoteData({ ...quoteData, companyName: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Company Details"
              id="outlined-start-adornment"
              sx={{width:"100%" }}
              value ={quoteData.companyDetails}
              onChange={(e) => setQuoteData({ ...quoteData, companyDetails: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              label="Client Requester Name"
              id="outlined-start-adornment"
              sx={{width:"100%"}}
              value ={quoteData.clientRequesterName}
              onChange={(e) => setQuoteData({ ...quoteData, clientRequesterName: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              label="Job Title"
              id="outlined-start-adornment"
              sx={{width:"100%"}}
              value ={quoteData.title}
              onChange={(e) => setQuoteData({ ...quoteData, title: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Address"
              id="outlined-start-adornment"
              sx={{width:"100%"}}
              value ={quoteData.address}
              onChange={(e) => setQuoteData({ ...quoteData, address: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              label="Email"
              id="outlined-start-adornment"
              sx={{width:"100%"}}
              type="email"
              value ={quoteData.email}
              onChange={handleEmailChange}
              error={!!emailError}
              helperText={emailError}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Phone Number"
              id="outlined-start-adornment"
              sx={{width:"100%"}}
              type="tel"
              value ={quoteData.phone}
              onChange={handlePhoneChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel sx={{}}>Sale Item</InputLabel>
              <Select
                required
                multiple
                value={saleItem}
                onChange={(e)=> {handleChangeSaleItem(e, items._id)}}
                input={<OutlinedInput label="Sale Item" />}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
              >
                {items.map((item) => (

                  <MenuItem key={item._id} value={item.saleItem}>
                    <Checkbox checked={saleItem.indexOf(item.saleItem) > -1 }/>
                    <ListItemText primaryTypographyProps={{component: 'div', sx: { whiteSpace: 'normal' }}}
                      primary={item.saleItem}
                    />          
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

      {isDivVisible && (
        <Grid item xs={12} md={12}>

          {isEditingLetter ? (
            <Grid sx={{ mt: 1 }} item xs={12}>
              <TextField
                label='Body of the Letter'
                multiline
                fullWidth
                value={quoteData.letter}
                onChange={(e) => setQuoteData({ ...quoteData, letter: e.target.value })}

              />
              <Button size='small' variant="contained" onClick={handleToggleEditLetter}>
                Save Letter
              </Button>
            </Grid>
          ) : (
            <Grid sx={{ mt: 1 }} item xs={12}>
              <Typography variant='h6'>Body of the Letter</Typography>
              <Typography variant='body1'>{quoteData.letter}</Typography>
              
              <Button variant="outlined" onClick={handleToggleEditLetter}>
                  Edit Letter Body
              </Button>
            </Grid>
          )}
          {isEditingBackground ? (
            <Grid sx={{ mt: 1 }} item xs={12}>
              <TextField
                label='Background'
                multiline
                fullWidth
                value={quoteData.background}
                onChange={(e) => setQuoteData({ ...quoteData, background: e.target.value })}

              />
              <Button  variant="contained" onClick={handleToggleEditBackground}>
                Save Background
              </Button>
            </Grid>
          ) : (
            <Grid sx={{ mt: 1 }} item xs={12}>
              <Typography  variant='h6'>Background</Typography>
              <Typography variant='body1'>{quoteData.background}</Typography>
              
              <Button variant="outlined" onClick={handleToggleEditBackground}>
                  Edit Background
              </Button>
            </Grid>
          )}
          {isEditingProposal ? (
            <Grid sx={{ mt: 1 }} item xs={12}>
              <TextField
                label='Proposal'
                multiline
                fullWidth
                value={quoteData.proposal}
                onChange={(e) => setQuoteData({ ...quoteData, proposal: e.target.value })}

              />
              <Button  variant="contained" onClick={handleToggleEditProposal}>
                Save Proposal
              </Button>
            </Grid>
          ) : (
            <Grid sx={{ mt: 1 }} item xs={12}>
              <Typography variant='h6'>Proposal</Typography>
              <Typography variant='body1'>{quoteData.proposal}</Typography>
              
              <Button variant="outlined" onClick={handleToggleEditProposal}>
                  Edit Proposal
              </Button>
            </Grid>
          )}
          <Grid sx={{ mt: 2 }} item xs={12} md={6}></Grid>
          <Typography variant='h6'>Selected Sale Item</Typography>
          
          {selectedItemsData.map((selectedItem, index) => (
            <div key={index}>
              {isEditing && index === editingIndex ? (
                <div>
                  <Typography mb={2} mt={2}>{index+1}. {selectedItem.saleItem}</Typography>
                  <Button variant="contained" color="secondary" size="small"
                    onClick={() => setEditingIndex(null)} // Close the editing view
                  >Cancel Customize</Button>

                  <EditSaleItem
                    item={selectedItem}
                    onSave={(editedItem) => {
                      setQuoteData((prevData) => ({
                        ...prevData,
                        saleItem: editedItem,
                      }));

                      const updatedSelectedItemsData = [...selectedItemsData];
                      updatedSelectedItemsData[index] = editedItem;
                      setSelectedItemsData(updatedSelectedItemsData);

                      setEditingIndex(null); 
                    }}
                  />
                </div>         
              ) : (
                <div>
                  <Typography mb={2} mt={2}>{index+1}. {selectedItem.saleItem}</Typography>
                  <Button variant="contained" color="primary" size="small"
                    onClick={() => setEditingIndex(index)} 
                  >Customize</Button>
                </div>
              )}
            </div>
          ))}
        </Grid>
      )}
          <Grid item xs={12} md={4}></Grid>
          <Grid sx={{ mt: 5, mb: 5 }} item xs={6} md={4}>
            <Button 
              sx={{ mr: 2}}
              variant="contained" 
              color="primary" 
              size="medium" 
              type="submit"
              >Generate
            </Button>
          
          
            <Button  
              variant="contained" 
              color="error" 
              size="medium" 
              onClick={() => {clear()}}
              >Clear
            </Button>
           </Grid>
          <Grid item xs={12} md={4}></Grid>
        </Grid>
      </form>
    </div>
  );
}

export default CreateProposal