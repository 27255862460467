import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Form from '../Form/Item';
import {getItems} from '../../actions/items';
import './styles.css'

const SaleItems = () => {
  const [currentId, setCurrentId] = useState(0);  
  const dispatch = useDispatch();

  useEffect(() => {   
    dispatch(getItems());  
  }, [currentId, dispatch]);

  return (
           
    <div className="content">  
      <h1>Add Sale Item</h1>                       
      <Form currentId={currentId} setCurrentId={setCurrentId} />                            
    </div>
  );
};

export default SaleItems;
