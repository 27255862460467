import { StyleSheet, Font } from "@react-pdf/renderer";

Font.register({
  family: 'Ubuntu',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf',
    },
    {
      src: 'https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf',
      fontWeight: 'bold',
    },
    {
      src: 'https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf',
      fontWeight: 'normal',
      fontStyle: 'italic',
    },
  ],
});

const style = StyleSheet.create({

  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  top: {
    fontFamily: 'Times-BoldItalic',
    fontSize: 20,
    fontWeight: 1,
    fontStyle: 'italic',
    textAlign: "right",
    color: '#336699'
  },
  blueLine: {
    height: '3px',
    width: '100%',
    backgroundColor: '#336699'
  },
  company: {
    fontFamily: 'Ubuntu',
    marginTop: '-20px',
    fontSize: 16,
    fontWeight: 400,
    fontStyle: 'italic',
    textAlign: "left",
  },
  details: {
    fontSize: 10,
    fontWeight: 100,
    textAlign: "left",
  },
  quoteNumber: {
    fontFamily: 'Ubuntu',
    marginTop: '50px',
    fontSize: 12,
    fontWeight: 400,
    textAlign: "left",
  },
  private: {
    fontSize: 10,
    fontWeight: 100,
    textAlign: "left",
  },
  table: { 
    marginTop: 10,
    marginBottom: 10,
    display: "table", 
    width: "auto", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderRightWidth: 0, 
    borderBottomWidth: 0 
  },
  tableRow: { 
    // margin: "auto", 
     flexDirection: "row" 
   }, 
   tableCol1: { 
    width: "5%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  },
  tableCol2: { 
    width: "20%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  },
  tableCol3: { 
    width: "51%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  },
  tableCol4: { 
    width: "12%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  },
  tableCol5: { 
    width: "12%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  },
  tableCell: { 
    fontFamily: 'Ubuntu',
    marginLeft: "10", 
    marginTop: 5, 
    fontSize: 10,
    width: 'auto' 
  },
  tableCellTitle: { 
    fontFamily: 'Ubuntu',
   textAlign: 'center', 
    marginTop: 5, 
    fontSize: 10,
    width: 'auto',
    color: '#336699' 
  },
  tableCellRight: { 
    textAlign: 'right',  
    marginTop: 10, 
    marginRight: 3, 
    fontSize: 10,
    width: 'auto' 
  },

  footer: {
    fontSize: 8,
    textAlign: "left",
    color: '#2E2E2E'
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    marginTop: 80,
    marginBottom: 30,
    color: '#073763'
  },
  text: {
    margin: 12,
    fontSize: 12,
    textAlign: "justify",
    color: "#1F1424",
    fontFamily: 'Ubuntu',
    fontWeight: 1,
    letterSpacing: 0.2
  },
  bold: {
    margin: 2,
    fontSize: 11,
    fontWeight: 500,
    textAlign: "justify",
    color: "#1F1424",
  },
  date: {
    fontSize: 8,
    margintop: 2,
    textAlign: "right",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },

  logo: {
    left:400,
    width: 120,
    paddingTop: 50,
  }

});

export default style