import React, { useState } from 'react';

const MockDatabase = [
  { id: 1, name: 'Item 1', data: ['Data A', 'Data B', 'Data C'] },
  { id: 2, name: 'Item 2', data: ['Data X', 'Data Y', 'Data Z'] },
  // ... other items
];

function Tester({ item, onSave }) {
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItemData, setSelectedItemData] = useState([]);

  const handleItemSelect = (itemId) => {
    const selectedItem = MockDatabase.find(item => item.id === itemId);
    setSelectedItems(prevSelectedItems => [...prevSelectedItems, selectedItem]);
    setSelectedItemData(selectedItem.data);
  };

  const handleDataEdit = (index, newData) => {
    const updatedData = [...selectedItemData];
    updatedData[index] = newData;
    setSelectedItemData(updatedData);
  };

  const handleSaveState = () => {
    // Update your state management here (e.g., Redux, Context) without affecting the database.
    // For this example, we'll just log the updated data for each selected item.
    selectedItems.forEach(item => {
      console.log(`Updated Data for ${item.name}:`, selectedItemData);
    });
  };
  console.log(selectedItemData)

  return (
    <div>
      <h1>Select and Edit Items</h1>
      <div>
        <h2>Select Items:</h2>
        <ul>
          {MockDatabase.map(item => (
            <li key={item.id}>
              <button onClick={() => handleItemSelect(item.id)}>{item.name}</button>
            </li>
          ))}
        </ul>
      </div>
      {selectedItems.length > 0 && (
        <div>
          <h2>Edit Data for Selected Items:</h2>
          <ul>
            {selectedItemData.map((data, index) => (
              <li key={index}>
                <input
                  type="text"
                  value={data}
                  onChange={(e) => handleDataEdit(index, e.target.value)}
                />
              </li>
            ))}
          </ul>
          <button onClick={handleSaveState}>Save State</button>
        </div>
      )}
    </div>
  );
}

export default Tester;
