import React, { useEffect, useState } from 'react'
import { CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route, useLocation } from 'react-router-dom';
import { PDFViewer } from '@react-pdf/renderer';
import Quote from "./Quote"
import Details from './QuoteDetails'
import { getQuote } from '../../actions/quotes';
import './styles.css'

const PDFViewAdmin = () => {
  const location = useLocation();
  const id = location.state.id;

  const dispatch = useDispatch()
  const {quote, quotes, isLoading} = useSelector((state) => state.quotes)
  useEffect(() => {   
    dispatch(getQuote(id));  
  }, [id, dispatch]);

  if(!quote) return  <div>Loading</div>

    return(
  
        <div>
        <PDFViewer className="pdf">
           <Quote quote={quote}/>
        </PDFViewer>
        </div>

    )
}

export default PDFViewAdmin