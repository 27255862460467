import { START_LOADING, END_LOADING, FETCH_ALL_QUOTE, FETCH_QUOTE,FETCH_NEW, CREATE_QUOTE, UPDATE_QUOTE, DELETE_QUOTE } from '../constants/actionTypes';

import * as api from '../api/index.js';


export const getQuotes = (email) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.fetchQuotes();

    data.sort(function(b,a) {
      return (a.quoteNumber > b.quoteNumber) ? 1 : ((b.quoteNumber > a.quoteNumber) ? -1 : 0);
   });
    dispatch({ type: FETCH_ALL_QUOTE, payload: data });
    dispatch({ type: END_LOADING });

    console.log(data)
  } catch (error) {
    console.log(error.message);
  }
};

export const getQuote = (id) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });

    const { data } = await api.fetchQuote(id);
    dispatch({ type: FETCH_QUOTE, payload: { quote: data } });  
  
  } catch (error) {
    console.log(error.message);
  }
};

export const getNew = (uniqueID) => async (dispatch) => {

  const it = String(uniqueID)

  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.fetchQuotes();
    const filter = data.find((data)=> data.uniqueID===it)

    dispatch({ type: FETCH_ALL_QUOTE, payload: filter });
    dispatch({ type: END_LOADING });
    console.log(data)
    console.log(filter)
    console.log(it)
  } catch (error) {
    console.log(error.message);
  }
};

export const createQuote = (quote) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.createQuote(quote);
    dispatch({ type: CREATE_QUOTE, payload: data });

   // navigate(`/posts/${data._id}`);
   
  } catch (error) {
    console.log(error.message);
  }
};

export const deleteQuote = (id) => async (dispatch) => {
  try {
    await api.deleteQuote(id);
    dispatch({ type: DELETE_QUOTE, payload: id });
  } catch (error) {
    console.log(error.message);
  }
};
