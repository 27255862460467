import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Avatar, Button, Paper, Grid, Typography, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import jwt_decode from 'jwt-decode';
import { signin, signup } from '../../actions/auth';
import { AUTH } from '../../constants/actionTypes';
import './styles.css';
import Input from './Input';

const initialState = { firstName: '', lastName: '', email: '', password: '', confirmPassword: '' };

const Setup = () => {
  const [form, setForm] = useState(initialState);
  const dispatch = useDispatch();
  const history = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => setShowPassword(!showPassword);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(signup(form, history)); 
  };

  

  const handleChange = (e) => setForm({ ...form, [e.target.name]: e.target.value });

  return (
    <Container justify-content='center' component="main" maxWidth="xs">
      <Paper elevation={3} className="paper">
        <Avatar >
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">Sign up</Typography>
        <form className={'formAuth'} onSubmit={handleSubmit}>
          <Grid container spacing={2}>

            <>
              <Input name="firstName" label="First Name" handleChange={handleChange} autoFocus half />
              <Input name="lastName" label="Last Name" handleChange={handleChange} half />
            </>
            <Input name="email" label="Email Address" handleChange={handleChange} type="email" />
            <Input name="password" label="Password" handleChange={handleChange} type={showPassword ? 'text' : 'password'} handleShowPassword={handleShowPassword} />
            <Input name="confirmPassword" label="Repeat Password" handleChange={handleChange} type="password" /> 
            </Grid>

          <Grid item>
            <div className='spacer'></div>

          <Button className='btnSignin'  type="submit" fullWidth variant="contained" color="primary" >
             Sign Up
          </Button >
          <div className='spacer'></div>

          <Grid item justify="center">

          </Grid>
          <div className='spacer'></div>

              </Grid>

        </form>
      </Paper>
    </Container>
  );
};

export default Setup;