import React, {useState, useEffect} from "react";
import { Sidenav, Nav } from 'rsuite';
import { AppBar, Typography, Avatar, Button, Toolbar } from '@mui/material';
import { Link, useHistory, useLocation, NavLink, useNavigate } from 'react-router-dom';
import PlusIcon from '@rsuite/icons/Plus';
import ListIcon from '@rsuite/icons/List';
import SiteIcon from '@rsuite/icons/Site';
import PageIcon from '@rsuite/icons/Page';
import EditIcon from '@mui/icons-material/Edit';
import { useDispatch, useSelector } from 'react-redux';
import * as actionType from '../../constants/actionTypes';
import decode from 'jwt-decode';
import { setIsAdmin } from '../../actions/admin';
import Logo from '../../images/cmslogo.png'


import './style.css'

const Navigation = (props) => {
   // const isAdmin = useSelector((state) => state.admin);
    const {isAdmin} = props
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [isNavExpanded, setIsNavExpanded] = useState(false);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
    console.log("is admin", isAdmin)
    const logout = () => {
      dispatch({ type: actionType.LOGOUT });
      dispatch(setIsAdmin(false));
  
      navigate('/auth');
  
      setUser(null);
    };

    useEffect(() => {
      const token = user?.token;
  
      if (token) {
        const decodedToken = decode(token);
  
        if (decodedToken.exp * 1000 < new Date().getTime()) logout();
      }
  
      setUser(JSON.parse(localStorage.getItem('profile')));
    }, [location]);

    const NavLink = React.forwardRef(({ href, children, ...rest }, ref) => (
      <Link ref={ref} to={href} {...rest}  >
        {children}
      </Link>
    ));

return(

<div className="nav">
    <button
        type="button"
        className="hamburger"
        onClick={() => {setIsNavExpanded(!isNavExpanded);}}>
        {/* icon from heroicons.com */}
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
        </svg>
    </button>
    <Sidenav onClick={() => {setIsNavExpanded(!isNavExpanded);}} defaultOpenKeys={['3', '4']} >
      
      <Sidenav.Body className={isNavExpanded ? 'navBodyExpanded' : 'navBody'}>
      {user?.result ? (
          <div>
              <div className="flex flex-col items-center">
                <img className="logo mb-10" src={Logo} alt="CMS Consulting Inc. logo" style={{ width: '160px', height: 'auto' }} />
              </div>
            <div className="divNav">
              <Avatar alt={user?.result.name} src={user?.result.picture}>{user?.result.picture}</Avatar>
              <Typography  variant="h6">{user?.result.name}</Typography>
              <Link to={"profile"}><EditIcon sx={{ fontSize: 12 }}/> Edit Profile</Link>
            </div>
          <Nav onClick={() => {setIsNavExpanded(!isNavExpanded);}} activeKey="1" >
          <Nav.Item as={NavLink} href="/" eventKey="2" icon={<SiteIcon />}>
            Home
          </Nav.Item>
          <Nav.Item as={NavLink} href="createQuote" eventKey="1" icon={<PlusIcon />}>
            Create Quote
          </Nav.Item>
          {isAdmin && (
          <Nav.Item as={NavLink} href="createProposal" eventKey="1" icon={<PlusIcon />}>
            Create Proposal
          </Nav.Item>
          )}
          {isAdmin && (
          <Nav.Item as={NavLink} href="viewItems" eventKey="2" icon={<ListIcon />}>
            Sale Items
          </Nav.Item>
          )}
          <Nav.Item as={NavLink} href="viewQuote" eventKey="2" icon={<PageIcon />}>
            Quote Generated
          </Nav.Item>
          {isAdmin && (
          <Nav.Item as={NavLink} href="viewProposal" eventKey="2" icon={<PageIcon />}>
            Proposal Generated
          </Nav.Item>
          )}
          <div className="divNav mt-20">
            <Button variant="contained"  color="success" onClick={logout}>Logout</Button>
          </div>
        </Nav>
          </div>
          
        ) : (
          <div>
            <div className="divNav">
              <Button component={Link} to="/auth" variant="contained" color="primary" style={{ color: 'white' }}>Sign In</Button>
            </div>
          <Nav onClick={() => {setIsNavExpanded(!isNavExpanded);}} activeKey="1" >
          <Nav.Item as={NavLink} href="createQuote" eventKey="1" icon={<PlusIcon />}>
            Create Qoute
          </Nav.Item>
        </Nav>
          
          </div>

        )}
        
        
          
      </Sidenav.Body>
    </Sidenav>
  </div>

    )
}

export default Navigation