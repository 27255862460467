import axios from 'axios';

const API = axios.create({ baseURL: process.env.REACT_APP_API_SERVER })
const API_Log = axios.create({ baseURL: process.env.REACT_APP_API_SERVER_LOG })

API.interceptors.request.use((req) => {
    if (localStorage.getItem('profile')) {
      req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`;
    }
    return req;
  });


  export const signIn = (formData) => API_Log.post('/user/signin', formData);
  export const signUp = (formData) => API_Log.post('/user/signup', formData);
  export const getUsers = () => API_Log.get('/user');
  export const fetchUser = (email) => API.get(`/user/${email}`);
  export const deleteUser = (id) => API_Log.delete(`/user/${id}`);

export const fetchItems = () => API.get('/items');
export const fetchItem = (name) => API.get(`/items/${name}`);
export const createItem = (newItem) => API.post('/items', newItem);
export const updateItem = (id, updatedItem) => API.patch(`/items/${id}`, updatedItem);
export const deleteItem = (id) => API.delete(`/items/${id}`);

export const fetchQuotes = () => API.get('/quotes');
export const fetchQuote = (id) => API.get(`/quotes/${id}`);
export const fetchNew = (uniqueID) => API.get(`/quotes/${uniqueID}`);
export const createQuote = (newQuote) => API.post('/quotes', newQuote);
export const updateQuote = (id, updatedQuote) => API.patch(`/quotes/${id}`, updatedQuote);
export const deleteQuote = (id) => API.delete(`/quotes/${id}`);

export const fetchProposals = () => API.get('/proposals');
export const fetchProposal = (id) => API.get(`/proposals/${id}`);
export const fetchNewProposal = (uniqueID) => API.get(`/proposals/${uniqueID}`);
export const createProposal = (newProposal) => API.post('/proposals', newProposal);
export const updateProposal = (id, updatedProposal) => API.patch(`/proposals/${id}`, updatedProposal);
export const deleteProposal = (id) => API.delete(`/proposals/${id}`);