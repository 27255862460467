import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateQuote, getQuotes, deleteQuote} from '../../actions/quotes';
import { getItem } from '../../actions/items';
import { Page, Text, Image, Document, View } from "@react-pdf/renderer";
import logo from "../../images/CMSlogo.jpg";

import styles from './styles'

const Quote = ({quote}) => {

const listItem=quote.saleItem

console.log("Quote: ", quote)
console.log("ListItem: ", listItem)


let itemTotal = 0;

 if (listItem.length>0){
    for (let i=0; i<listItem.length; i++){
       
        itemTotal += listItem[i].price * listItem[i].estimatedDuration
    }
}

console.log('itemTotal:', itemTotal)


  return (
    <Document title={quote.companyName +" Quote"}>
      <Page size="A4" style={styles.body}>
        
        <Text style={styles.top}>Quote</Text>
        <Text style={styles.blueLine}></Text>
        <Text style={styles.date}>{quote.createdAt.split('T')[0]}</Text>
        <Image style={styles.logo} src={logo} />

        <Text style={styles.company}>
            CMS Consulting Inc.
        </Text>
        <Text style={styles.details}>
            527 Queen Street
        </Text>
        <Text style={styles.details}>
            Frederiction, NB
        </Text>
        <Text style={styles.details}>
            1 (888) 346-7242
        </Text>
        
        <Text style={styles.quoteNumber}>
            Quote # {quote.quoteNumber}
        </Text>
        <Text style={styles.private}>
          Private and Confidential
        </Text>
        <Text style={styles.private}>
          {quote.companyName}, {quote.companyDetails}
        </Text>
        <Text style={styles.private}>
          ATTN: {quote.clientRequesterName}
        </Text>

        <View style={styles.table}> 
            <View style={styles.tableRow}> 
                <View style={styles.tableCol1}> 
                    <Text style={styles.tableCellTitle}>Qty</Text> 
                </View> 
                <View style={styles.tableCol2}> 
                    <Text style={styles.tableCellTitle}>Ref</Text> 
                </View> 
                <View style={styles.tableCol3}> 
                    <Text style={styles.tableCellTitle}>Description</Text> 
                </View> 
                <View style={styles.tableCol4}> 
                    <Text style={styles.tableCellTitle}>Rate</Text> 
                </View>
                <View style={styles.tableCol5}> 
                    <Text style={styles.tableCellTitle}>Estimated Total</Text> 
                </View>  
            </View>
          
            {listItem.map((item) => (
            <View style={styles.tableRow} key={item}> 
            
                <View style={styles.tableCol1}> 
                    <Text style={styles.tableCell}>1</Text> 
                </View> 
                <View style={styles.tableCol2}> 
                    <Text style={styles.tableCell}>{item.saleItemType}</Text> 
                </View> 
                <View style={styles.tableCol3}>
                    <Text style={styles.tableCell}>{item.saleItem}</Text>
                    <Text style={styles.tableCell}>Duration: {item.estimatedDuration} - {item.priceType}</Text>
                    <Text style={styles.tableCell}>Note:</Text> 
                </View>
                <View style={styles.tableCol4}> 
                    <Text style={styles.tableCell}>$ {item.price}</Text> 
                </View>
                <View style={styles.tableCol5}> 
                    <Text style={styles.tableCell}>$ {item.price * (item.estimatedDuration || 1)}</Text> 
                </View>  
                 
            </View>
            ))}
            <View style={styles.tableRow}> 
                <View style={styles.tableCol1}> 
                    <Text style={styles.tableCell}></Text> 
                </View> 
                <View style={styles.tableCol2}> 
                    <Text style={styles.tableCell}></Text> 
                </View> 
                <View style={styles.tableCol3}>
                    <Text style={styles.tableCellRight}>HST/TVH #83479 0859</Text> 
                </View>
                <View style={styles.tableCol4}> 
                    <Text style={styles.tableCellTitle}>Subtotal</Text> 
                </View>
                <View style={styles.tableCol5}> 
                    <Text style={styles.tableCell}>$ {itemTotal}</Text> 
                </View>  
            </View>
          </View>

        <Text style={styles.footer}>
            {listItem.map((item) => (
            <React.Fragment key={item._id}>
              {item.terms}{"\n"}
            </React.Fragment>
          ))}
        </Text>

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
        />
      </Page>
    </Document>
  )
};

export default Quote;