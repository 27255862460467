import React, {useState, useEffect} from "react";
import './styles.css'
import Image from '../../images/cover.jpg'
import Logo from '../../images/CMSlogo.jpg'
import { Avatar, Typography, Grid, Button } from "@mui/material";

import { useDispatch, useSelector } from 'react-redux';
import { getUsers, getUser} from '../../actions/user'
import EditIcon from '@mui/icons-material/Edit';
import Setup from "./Setup"
import { FETCH_USER } from "../../constants/actionTypes";

const styles = {
    paperContainer: {
        width: '100%',
        height: 'auto',
     //   backgroundImage: `url(${Image})`,
        color: '#000',
      //  backgroundRepeat: 'no-repeat',
      //  backgroundPosition: 'center',
      //  backgroundSize: 'cover',

    }
};

const Profile = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getUsers());
      }, [dispatch]);

    const quote = JSON.parse(localStorage.getItem('profile'))
    const profile = quote?.result
    const user = useSelector((state) => (profile.email ? state.users.find((user) => user.email === profile.email) : null));

    const isUserSignUp = profile?.email && !user;

      const name = profile?.given_name
      console.log('Name:', name)



    return(
        
        <div style={styles.paperContainer}>
            {isUserSignUp && (
            <Grid container direction="column" alignItems="center">
                <Typography sx={{mb:5, mt:5}} className="titleProfile">User Profile</Typography>
                
                <Setup name={name}></Setup>
            </Grid>
            )}

            <Avatar display="flex" alignItems="center" sx={{m:'auto', width: 100, height: 100}} alt={profile.name} src={profile.picture}>{profile.picture}</Avatar>
            
            <Grid container spacing={0} justifyContent='center'>
                
                <Grid item xs={6} md={6}> 
                    <Typography variant='overline' display='block' ml={2} mt={2}>Full Name </Typography>
                    <Typography variant='h5' ml={2} mt={-1}>{profile?.name}</Typography>   
                </Grid>
                <Grid item xs={6} md={6}> 
                    <Typography variant='overline' display='block' ml={2} mt={2}>Email</Typography>
                    <Typography variant='h5' ml={2} mt={-1}>{profile?.email}</Typography>   
                </Grid>
            </Grid>
        
        </div>
  
    )
}

export default Profile