export const CREATE_ITEM = 'CREATE_ITEM';
export const UPDATE_ITEM = 'UPDATE_ITEM';
export const DELETE_ITEM = 'DELETE_ITEM';
export const FETCH_ALL_ITEM = 'FETCH_ALL_ITEM';
export const FETCH_ITEM = 'FETCH_ITEM';

export const CREATE_QUOTE = 'CREATE_QUOTE';
export const UPDATE_QUOTE = 'UPDATE_QUOTE';
export const DELETE_QUOTE = 'DELETE_QUOTE';
export const FETCH_ALL_QUOTE = 'FETCH_ALL_QUOTE';
export const FETCH_QUOTE = 'FETCH_QUOTE';
export const FETCH_NEW = 'FETCH_NEW';

export const CREATE_PROPOSAL = 'CREATE_PROPOSAL';
export const UPDATE_PROPOSAL = 'UPDATE_PROPOSAL';
export const DELETE_PROPOSAL = 'DELETE_PROPOSAL';
export const FETCH_ALL_PROPOSAL = 'FETCH_ALL_PROPOSAL';
export const FETCH_PROPOSAL = 'FETCH_PROPOSAL';
export const FETCH_NEW_PROPOSAL = 'FETCH_NEW_PROPOSAL';

export const END_LOADING = 'END_LOADING';
export const START_LOADING = 'START_LOADING';

export const AUTH = 'AUTH';
export const LOGOUT = 'LOGOUT';
export const GET_USERS = 'GET_USERS';
export const FETCH_USER = 'GET_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';