import { StyleSheet, Font } from "@react-pdf/renderer";

Font.register({
  family: 'Ubuntu',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf',
    },
    {
      src: 'https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf',
      fontWeight: 'bold',
    },
    {
      src: 'https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf',
      fontWeight: 'normal',
      fontStyle: 'italic',
    },
  ],
});

Font.registerHyphenationCallback(word => [word]);

const style = StyleSheet.create({

  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 60,
  },
  original: {
    fontFamily: 'Ubuntu',
    fontSize: 35,
    fontWeight: 1,
    textAlign: "left",
    color: '#FF0000',
    marginTop: -100,
    marginLeft: 10,
    paddingBottom: 100,
  },

  logo: {
    left:357,
    width: 120,
   
  },


  cmsDetails: {
    fontFamily: 'Ubuntu',
    fontSize: 10,
    fontWeight: 1,
    textAlign: "right",
    color: '#000000',
    paddingTop: '1px',
  },

  firstPage: {
    fontFamily: 'Ubuntu',
    fontSize: 12,
    lineHeight: 1.2,
    textAlign: "left",
    color: '#000000',
    paddingBottom: '1px',
  },

  reference: {
    fontFamily: 'Ubuntu',
    fontSize: 12,
    textDecoration: 'underline',
  },
  bold: {
    fontFamily: 'Ubuntu',
    fontSize: 12,
  },

  titleBlue: {
    display: 'flex',
    fontFamily: 'Helvetica-Bold',
    fontSize: 15,
    fontWeight: 1,
    textAlign: "left",
    color: '#FFFFFF',
    padding: 5,
    backgroundColor: '#333399',
    marginBottom: 10
  },

  bodyBlue: {
    fontFamily: 'Ubuntu',
    fontSize: 10,
    fontWeight: 1,
    textAlign: "left",
    color: '#000000',
    marginBottom: 10
  },

  titleBlack: {
    display: 'flex',
    fontFamily: 'Helvetica-Bold',
    fontSize: 14,
    fontWeight: 1,
    textAlign: "left",
    color: '#000000',
    marginBottom: 5,
    marginTop: 20
  },

  titleBlackTerms: {
    display: 'flex',
    fontFamily: 'Helvetica-Bold',
    fontSize: 12,
    fontWeight: 1,
    textAlign: "left",
    color: '#000000',
    marginBottom: 5,
    marginTop: 20
  },

  titleBlackApproach: {
    display: 'flex',
    fontFamily: 'Helvetica-Bold',
    fontSize: 18,
    fontWeight: 1,
    textAlign: "left",
    color: '#000000',
    marginBottom: 5,
    marginTop: 20
  },

  titleSched: {
    display: 'flex',
    fontFamily: 'Helvetica-Bold',
    fontSize: 14,
    fontWeight: 1,
    textAlign: "center",
    color: '#000000',
    marginBottom: 5,
    marginTop: 20
  },

  bodyBlack: {
    fontFamily: 'Ubuntu',
    fontSize: 12,
    fontWeight: 1,
    textAlign: "left",
    color: '#000000',
    marginBottom: 1
  },

  normalText: {
    fontFamily: 'Ubuntu',
    fontSize: 12,
    fontWeight: 1,
    textAlign: "left",
    color: '#000000',
    marginBottom: 1,
    lineHeight: 1.2,
    wrap:"false"
  },

  table: { 
    marginTop: 10,
    marginBottom: 10,
    display: "table", 
    width: "auto", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderRightWidth: 0, 
    borderBottomWidth: 0 
  },
  tableRow: { 
    // margin: "auto", 
     flexDirection: "row" 
   }, 
   tableCol1: { 
    width: "40%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  },
  tableCol2: { 
    width: "20%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  },
  tableCol3: { 
    width: "20%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  },
  tableCol4: { 
    width: "20%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  },

  tableCell: { 
    fontFamily: 'Helvetica-Bold',
    marginLeft: "10", 
    marginTop: 5, 
    marginBottom: 5, 
    fontSize: 10,
    width: 'auto' 
  },
  tableCellTitle: { 
    fontFamily: 'Helvetica-Bold',
    textAlign: 'center', 
    marginTop: 5, 
    marginBottom: 5, 
    fontSize: 10,
    width: 'auto',
    color: '#336699' 
  },
  tableCellRight: { 
    textAlign: 'center',  
    marginTop: 5,  
    fontSize: 10,
    width: 'auto' 
  },

  footerLogo: {
    position: "absolute",
    marginLeft:60,
    width: 80,
    bottom: 15,
   
  },
  pageNumber: {
    position: "absolute",
    fontSize: 9,
    bottom: 30,
    right: 30,
    textAlign: "center",
    color: "grey",
  },
  footer: {
    position: "absolute",
    fontSize: 9,
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },

});

export default style