import { combineReducers } from 'redux';

import items from './items';
import quotes from './quotes'
import proposals from './proposals'
import auth from './auth'
import users from './users'
import admin from './admin'

export const reducers = combineReducers({ items, quotes, auth, users, proposals, admin });
